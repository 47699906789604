.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.bglight{
  background-color: #f2f2f2;
  padding: 20px;
}

.red-alert{
  color: red;
}


.table-form>tbody>tr{
  height: 80px;
}

.table-form>tbody>tr:nth-child(odd){
  background-color: #f9f9f9
}

.table-form>tbody>tr>td{
  padding: 10px;
}

*{
  font-family: 'Roboto', sans-serif;
}


a{
  color: inherit ;
}

.download-table-xls-button{
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 5px 7px 5px 7px;
  font-size: 0.9em;
  border-radius: 3px;
}